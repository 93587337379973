// export const API_URL = (() => {
//   return window.location.host === 'localhost:5173'
//     ? 'https://api.menahub.dev.wtmsrv.com'
//     : window.location.host.includes('staging')
//       ? `https://api.menahub.staging.wtmsrv.com`
//       : `https://${window.location.host}`;
// })();
export const API_URL = (() => {
  return window.location.host.includes('localhost') ||
    window.location.host.includes('local')
    ? 'https://api.menahub.local.wtmsrv.com'
    : window.location.host.includes('staging')
      ? 'https://api.menahub.staging.wtmsrv.com'
      : 'https://api.menahub.co';
})();
export const API_KEY = 'TUVOQUhVQkFQSUtFWUAyMDIz';
